import { render, staticRenderFns } from "./Latestfeatures.vue?vue&type=template&id=504b6642&scoped=true"
import script from "./Latestfeatures.vue?vue&type=script&lang=js"
export * from "./Latestfeatures.vue?vue&type=script&lang=js"
import style0 from "./Latestfeatures.vue?vue&type=style&index=0&id=504b6642&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "504b6642",
  null
  
)

export default component.exports